import { formatISO } from "date-fns"
import { useMemo } from "react"
import { useApi } from "src/hooks/api"
import { queryClient } from "./client"
import {
  getQueryKey,
  useDeleteQuery,
  useGetQuery,
  usePatchQuery,
  usePostQuery,
  useRouteParams
} from "./helpers"
import { ManualInvoiceParams, OrganizationParams } from "./types"

//--------------------------------------------------------------------------------------------------
// URLs
//--------------------------------------------------------------------------------------------------

/** @returns /manage/billing/accounts/ */
export const getBillingAccountListUrl = () => "/manage/billing/accounts/"

/** @returns /manage/billing/accounts/${orgUuid}/?org=${orgUuid} */
export const getBillingAccountUrl = ({ orgUuid }: Required<OrganizationParams>) =>
  `/manage/billing/accounts/${orgUuid}/?org=${orgUuid}`

/** @returns /billing/plans/current/?org=${orgUuid} */
export const getBillingCurrentPlanUrl = ({ orgUuid }: Required<OrganizationParams>) =>
  `/billing/plans/current/?org=${orgUuid}`

/** @returns /manage/billing/accounts/${orgUuid}/plan/?org=${orgUuid} */
export const getBillingPlanUrl = ({ orgUuid }: Required<OrganizationParams>) =>
  `/manage/billing/accounts/${orgUuid}/plan/?org=${orgUuid}`

/** @returns /manage/billing/accounts/${orgUuid}/service/?org=${orgUuid} */
export const getBillingServiceUrl = ({ orgUuid }: Required<OrganizationParams>) =>
  `/manage/billing/accounts/${orgUuid}/service/?org=${orgUuid}`

/** @returns /sales/billing/refill/?org=${orgUuid} */
export const getBillingRefillUrl = ({ orgUuid }: Required<OrganizationParams>) =>
  `/sales/billing/refill/?org=${orgUuid}`

/** @returns /manage/billing/accounts/${orgUuid}/subscription/?org=${orgUuid} */
export const getBillingSubscriptionUrl = ({ orgUuid }: Required<OrganizationParams>) =>
  `/manage/billing/accounts/${orgUuid}/subscription/?org=${orgUuid}`

/** @returns /manage/billing/accounts/${orgUuid}/manual_invoices/?org=${orgUuid} */
export const getBillingManualInvoiceListUrl = ({ orgUuid }: Required<OrganizationParams>) =>
  `/manage/billing/accounts/${orgUuid}/manual_invoices/?org=${orgUuid}`

/** @returns /manage/billing/accounts/${orgUuid}/manual_invoices/?org=${orgUuid} */
export const getBillingManualInvoiceUrl = ({ orgUuid, reference }: Required<ManualInvoiceParams>) =>
  `/manage/billing/accounts/${orgUuid}/manual_invoices/${reference}/?org=${orgUuid}`

/** @returns /manage/billing/accounts/${orgUuid}/manual_invoices/?org=${orgUuid} */
export const getBillingManualInvoiceDownloadUrl = ({
  orgUuid,
  reference
}: Required<ManualInvoiceParams>) =>
  `/manage/billing/accounts/${orgUuid}/manual_invoices/${reference}/download/?org=${orgUuid}`

//--------------------------------------------------------------------------------------------------
// Queries & mutations
//--------------------------------------------------------------------------------------------------

export const useBillingAccountList = () => {
  const url = getBillingAccountListUrl()

  return useGetQuery<api.billing.PaginatedPrivateAccountList>("billing", url)
}

export const useBillingAccount = (params?: OrganizationParams) => {
  const mergedParams = useRouteParams(params)
  const url = getBillingAccountUrl(mergedParams)

  return useGetQuery<api.billing.PrivateDetailedAccount>("billing", url)
}

export const useBillingCurrentPlan = (params?: OrganizationParams) => {
  const mergedParams = useRouteParams(params)
  const url = getBillingCurrentPlanUrl(mergedParams)

  return useGetQuery<api.billing.Plan>("billing", url)
}

export const useUpdateBillingAccount = (params?: OrganizationParams) => {
  const mergedParams = useRouteParams(params)
  const url = getBillingAccountUrl(mergedParams)
  const listUrl = getBillingAccountListUrl()

  type TRequest = DeepPartial<api.billing.PatchedPrivateDetailedAccount>
  type TResponse = api.billing.PrivateDetailedAccount

  return usePatchQuery<TRequest, TResponse>("billing", url, [listUrl])
}

export const useRefillCredits = (params?: OrganizationParams) => {
  const mergedParams = useRouteParams(params)
  const url = getBillingRefillUrl(mergedParams)
  const accountUrl = getBillingAccountUrl(mergedParams)
  const accountListUrl = getBillingAccountListUrl()

  type TRequest = api.billing.Refill
  type TResponse = api.billing.Refill

  return usePostQuery<TRequest, TResponse>("billing", url, [accountUrl, accountListUrl])
}

export const useSubscribeToPlan = (params?: OrganizationParams) => {
  const { jsonPost } = useApi("billing")
  const mergedParams = useRouteParams(params)
  const url = getBillingPlanUrl(mergedParams)
  const accountUrl = getBillingAccountUrl(mergedParams)
  const accntListUrl = getBillingAccountListUrl()

  type TRequest = api.billing.PlanCreation
  type TResponse = api.billing.PlanCreation

  return {
    mutationFn: (data: TRequest) => jsonPost<TResponse>(url, data),
    onSuccess: async () => {
      await Promise.all([
        queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("billing", accountUrl) }),
        queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("billing", accntListUrl) })
      ])
    }
  }
}

export const useSubscribeToServiceLevel = (params?: OrganizationParams) => {
  const { jsonPost } = useApi("billing")
  const mergedParams = useRouteParams(params)
  const url = getBillingServiceUrl(mergedParams)
  const accountUrl = getBillingAccountUrl(mergedParams)
  const accntListUrl = getBillingAccountListUrl()

  type TRequest = api.billing.ServiceCreation
  type TResponse = api.billing.ServiceCreation

  return {
    mutationFn: (data: TRequest) => jsonPost<TResponse>(url, data),
    onSuccess: async () => {
      await Promise.all([
        queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("billing", accountUrl) }),
        queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("billing", accntListUrl) })
      ])
    }
  }
}

export const usePlayCosts = (
  range: { month: Date } | { from: Date; to: Date },
  params?: OrganizationParams
) => {
  const mergedParams = useRouteParams(params)
  const { month, from, to } = range as { month: Date; from: Date; to: Date }
  const { pathName, qs } = useMemo(
    () =>
      month
        ? {
            pathName: "month",
            qs: new URLSearchParams([
              ["month", formatISO(month, { representation: "date" }).slice(0, 7)],
              ["org", mergedParams.orgUuid || ""]
            ]).toString()
          }
        : {
            pathName: "custom",
            qs: new URLSearchParams([
              ["from", formatISO(from, { representation: "date" })],
              ["to", formatISO(to, { representation: "date" })],
              ["org", mergedParams.orgUuid || ""]
            ]).toString()
          },
    [from, month, to, mergedParams.orgUuid]
  )
  const url = `/billing/costs/${pathName}/?${qs}`

  return useGetQuery<api.billing.Cost>("billing", url)
}

export const useLinkCosts = (
  range: { month: Date } | { from: Date; to: Date },
  params?: OrganizationParams
) => {
  const mergedParams = useRouteParams(params)
  const { month, from, to } = range as { month: Date; from: Date; to: Date }
  const { pathName, qs } = useMemo(
    () =>
      month
        ? {
            pathName: "month",
            qs: new URLSearchParams([
              ["month", formatISO(month, { representation: "date" }).slice(0, 7)],
              ["org", mergedParams.orgUuid || ""]
            ]).toString()
          }
        : {
            pathName: "custom",
            qs: new URLSearchParams([
              ["from", formatISO(from, { representation: "date" })],
              ["to", formatISO(to, { representation: "date" })],
              ["org", mergedParams.orgUuid || ""]
            ]).toString()
          },
    [from, month, to, mergedParams.orgUuid]
  )
  const url = `/billing/costs/link/${pathName}/?${qs}`

  return useGetQuery<api.billing.LinkCost>("billing", url)
}

export const useGlobalCosts = (month: string, params?: OrganizationParams) => {
  const mergedParams = useRouteParams(params)
  const url = `/billing/global_costs/?org=${mergedParams.orgUuid}&month=${month}`

  return useGetQuery<api.billing.GlobalCost>("billing", url)
}

export const useCogsList = () => {
  const url = `/manage/billing/cogs/`

  return useGetQuery<api.billing.Cogs[]>("billing", url)
}

export const useCogsDetail = (month?: string) => {
  const url = `/manage/billing/cogs/${month}/`

  return useGetQuery<api.billing.CogsDetail>("billing", url)
}

export const useManualInvoiceList = (params?: OrganizationParams) => {
  const mergedParams = useRouteParams(params)
  const url = getBillingManualInvoiceListUrl(mergedParams)

  return useGetQuery<api.billing.PaginatedManualInvoiceList>("billing", url)
}

export const useAddManualInvoice = (params?: OrganizationParams) => {
  const mergedParams = useRouteParams(params)
  const url = getBillingManualInvoiceListUrl(mergedParams)

  type TRequest = FormData
  type TResponse = api.billing.ManualInvoice & { uuid: string }

  return usePostQuery<TRequest, TResponse>("billing", url, [url], "form-data")
}

export const useDeleteManualInvoice = (params?: ManualInvoiceParams) => {
  const mergedParams = useRouteParams(params)
  const url = getBillingManualInvoiceUrl(mergedParams)

  return useDeleteQuery("billing", url, [getBillingManualInvoiceListUrl(mergedParams)])
}

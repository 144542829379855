import { QueryFunctionContext } from "@tanstack/react-query"
import { useApi } from "src/hooks/api"
import { useMatchesParams } from "src/hooks/router"
import { ApiType } from "src/services/api"
import { queryClient } from "./client"

export const useRouteParams = <TParams>(overrides?: TParams) => ({
  ...(useMatchesParams() as Required<TParams>),
  ...overrides
})

export const useGetQuery = <TResponse>(
  api: ApiType,
  url: string,
  options?: { headers?: Record<string, string> }
) => {
  const { jsonGet } = useApi(api)
  const { headers } = options || {}

  return {
    queryFn: ({ signal }: QueryFunctionContext) => jsonGet<TResponse>(url, { headers, signal }),
    queryKey: getQueryKey(api, url)
  } as const
}

export const usePostQuery = <TRequest extends {} | FormData, TResponse extends { uuid: string }>(
  api: ApiType,
  parentUrl: string,
  toRefetchUrls: string[],
  contentTypeRequest: "json" | "form-data" = "json",
  options?: {
    headers?: Record<string, string>
    onSuccess?: (data: TResponse) => Promise<void> | void
  }
) => {
  const { jsonPost, formDataPost } = useApi(api)
  const { headers } = options || {}

  return {
    mutationFn: (data: TRequest) =>
      contentTypeRequest === "json"
        ? jsonPost<TResponse>(parentUrl, data, headers)
        : // @ts-ignore
          formDataPost<TResponse>(parentUrl, data, headers),
    onSuccess: async (data: TResponse) => {
      const [path, queryString] = parentUrl.split("?")
      queryClient.setQueryData(getQueryKey(api, `${path}/${data.uuid}/?${queryString}`), data)
      await Promise.all([
        ...toRefetchUrls.map(toRefetchUrl =>
          queryClient.refetchQueries({ exact: true, queryKey: getQueryKey(api, toRefetchUrl) })
        ),
        options?.onSuccess?.(data)
      ])
    }
  } as const
}

export const usePatchQuery = <TRequest extends {}, TResponse extends {}>(
  api: ApiType,
  url: string,
  toRefetchUrls: string[],
  options?: {
    headers?: Record<string, string>
    onSuccess?: (data: TResponse) => Promise<void> | void
  }
) => {
  const { jsonPatch } = useApi(api)
  const { headers } = options || {}

  return {
    mutationFn: (data: TRequest) => jsonPatch<TResponse>(url, data, headers),
    onSuccess: async (data: TResponse) => {
      queryClient.setQueryData(getQueryKey(api, url), data)
      await Promise.all([
        ...toRefetchUrls.map(toRefetchUrl =>
          queryClient.refetchQueries({ exact: true, queryKey: getQueryKey(api, toRefetchUrl) })
        ),
        options?.onSuccess?.(data)
      ])
    }
  } as const
}

export const usePutQuery = <TRequest extends {}, TResponse extends {}>(
  api: ApiType,
  url: string,
  toRefetchUrls: string[],
  options?: {
    headers?: Record<string, string>
    onSuccess?: (data: TResponse) => Promise<void> | void
  }
) => {
  const { jsonPut } = useApi(api)
  const { headers } = options || {}

  return {
    mutationFn: (data: TRequest) => jsonPut<TResponse>(url, data, headers),
    onSuccess: async (data: TResponse) => {
      queryClient.setQueryData(getQueryKey(api, url), data)
      await Promise.all([
        ...toRefetchUrls.map(toRefetchUrl =>
          queryClient.refetchQueries({ exact: true, queryKey: getQueryKey(api, toRefetchUrl) })
        ),
        options?.onSuccess?.(data)
      ])
    }
  } as const
}

export const useDeleteQuery = (
  api: ApiType,
  url: string,
  toRefetchUrls: string[],
  options?: {
    confirmArg?: string
    headers?: Record<string, string>
    onSuccess?: () => Promise<void> | void
  }
) => {
  const { jsonDelete } = useApi(api)
  const { confirmArg, headers } = options || {}

  return {
    mutationFn: () => jsonDelete(url + (confirmArg ? `&${confirmArg}` : ""), headers),
    onSuccess: async () => {
      await Promise.all([
        ...toRefetchUrls.map(toRefetchUrl =>
          queryClient.refetchQueries({ exact: true, queryKey: getQueryKey(api, toRefetchUrl) })
        ),
        options?.onSuccess?.()
      ])

      setTimeout(() => queryClient.removeQueries({ queryKey: getQueryKey(api, url) }), 1_000)
    }
  } as const
}

const apiToRedundantPrefixes = {
  analytics: [],
  billing: ["/manage/billing", "/billing"],
  link: [],
  ott: ["/manage/ott"],
  upload: [],
  user: ["/manage/user"],
  www: []
} as const

export const getQueryKey = (api: ApiType, url: string) => {
  const [pathName, queryString] = url.split("?")
  const { org, ...queryParams } = Object.fromEntries(new URLSearchParams(queryString))

  // Leading and trailing slashes are required by all our APIs
  if (!pathName?.startsWith("/")) throw new Error(`URLs must start with a slash: ${pathName}`)
  if (!pathName?.endsWith("/")) throw new Error(`URLs must end with a slash: ${pathName}`)

  let filteredPath = pathName

  // Removing redundant prefix, if any
  for (const prefix of apiToRedundantPrefixes[api]) {
    filteredPath = filteredPath.replace(new RegExp(`^${prefix}/`), "/")
  }

  // Remove /organizations/:org because we insert ["organizations", org] anyway
  filteredPath = filteredPath.replace(`/organizations/${org}/`, "/")

  // Remove leading and trailing slashes so to not pollute the key with empty slots
  filteredPath = filteredPath.slice(1, -1)

  return [
    api,
    ...(org ? ["organizations", org] : []),
    ...filteredPath.split("/").map(decodeURIComponent),
    ...(Object.keys(queryParams).length > 0 ? [queryParams] : [])
  ]
}
